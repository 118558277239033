<template>
  <div id="quicklinks" class="bg-secondary-light">
    <div class="container pt-4 pb-8 sm:py-10">
      <div class="h2 pb-6">
        Snel naar...
      </div>
      <nav>
        <div class="flex-row justify-between xs:gradient-border">
          <ul class="grid grid-cols-1 pt-2px sm:grid-cols-2 md:grid-cols-3 sm:gap-8">
            <li v-for="link in links" :key="link.text">
              <button-gradient-border :icon="true" :link="link.url">
                <template v-slot:content>
                  {{ link.text }}
                </template>
              </button-gradient-border>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ButtonGradientBorder from '~/components/ButtonGradientBorder';

export default {
  components: {ButtonGradientBorder},
  computed: {
    ...mapState({
      links: state => state.menuQuick,
    }),
  },
};
</script>

<style lang="sass" scoped>
/deep/ li:last-child div
  border-bottom-width: 0

</style>
