<template>
  <div class="sm:gradient-border shadow-quicklink sm:hover:shadow-xl transition duration-300 ease-in-out">
    <template v-if="externalLink">
      <a :href="link" :download="download" :target="target">
        <div class="sm:pt-2px h-full">
          <div class="bg-white h-full pt-5 pb-0 sm:py-6 px-3 lg:px-5 sm:pl-5 font-semibold font-heading text-base sm:text-md md:text-lg">
            <div class="h-full border-b-2 border-gray-light sm:border-none pb-5 sm:p-0">
              <slot/>
            </div>
          </div>
        </div>
      </a>
    </template>

    <template v-else>
      <nuxt-link :to="link">
        <div class="sm:pt-2px h-full">
          <div class="bg-white h-full pt-5 pb-0 sm:py-6 px-3 lg:px-5 sm:pl-5 font-semibold font-heading text-base sm:text-md md:text-lg">
            <div class="h-full border-b-2 border-gray-light sm:border-none pb-5 sm:p-0">
              <template v-if="icon">
                <div class="text-xs sm:text-sm inline-block">
                  <font-awesome-icon :icon="['fal', 'arrow-right']" class="mr-2"/>
                </div>
              </template>
              <slot name="content">
                Content
              </slot>
            </div>
          </div>
        </div>
      </nuxt-link>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CardGradientBorder',
  props: {
    link: {
      type: String,
      default: null,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    externalLink: {
      type: Boolean,
      default: false,
    },
    download: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
  },
};
</script>
